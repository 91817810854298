<template>
  <v-card width="400" class="mx-auto mt-16 py-8" elevation="4">
    <v-card-title class="text-h5 text-center">Login</v-card-title>
    <v-card-text>
      <v-form
        ref="loginForm"
        v-model="loginFormValid"
        autocapitalize="on"
      >
        <v-text-field
          v-model="email"
          density="compact"
          label="email"
          :rules="loginRules.email"
          required
        />
        <v-text-field
          type="password"
          v-model="password"
          density="compact"
          label="password"
          :rules="loginRules.password"
          @keydown.enter="submit"
          required
        />
      </v-form>
    </v-card-text>
    <v-card-actions class=" justify-center">
      <v-btn
        @click.prevent="submit"
        color="primary"
        variant="flat"
        class="px-16"
      >
        Login
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script setup lang="ts">
import type { VForm } from 'vuetify/components';
useHead({
  title: 'Login',
})

definePageMeta({
  name: 'Login',
  description: 'Login to the application',
  layout: 'empty',
  auth: {
    unauthenticatedOnly: true,
    navigateAuthenticatedTo: '/',
  },
});
const email = ref('');
const password = ref('');
const loginForm = ref<VForm|null>(null);
const loginFormValid = ref(false);
const loginRules = {
  email: [(v: string) => !!v || 'Email is required'],
  password: [(v: string) => !!v || 'Password is required'],
};

const { signIn, token, data, status, lastRefreshedAt } = useAuth();

const submit = async () => {
  if (!(await loginForm.value?.validate())) {
    return;
  }
  // signIn({ email, password },{callbackUrl:'/workloads'})
  try {
    return await signIn(
      { email: email.value, password: password.value },
      { callbackUrl: '/workloads' },
    );
  } catch (error) {
    loginFormValid.value = false;
    console.log(error)
  }
};

</script>
